<!--
 * @Author: your name
 * @Date: 2020-06-03 08:58:30
 * @LastEditTime: 2020-07-28 17:42:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \src\layout\mainHeader.vue
--> 
<template>
  <div class="login_s">
    <div class="title_sa" v-if="type==1">
      <div class="ims">
        <img src="@/assets/images/header/telicon.png" /> |
        <img src="@/assets/images/header/wzicon.png" />|
        <div class="spsw" @click="car()">
          <el-badge :value="getcar" class="item" v-if="getcar"></el-badge>
          <img src="@/assets/images/header/caricon.png" />
        </div>|
        <img src="@/assets/images/header/usericon.png" @click="login()" />
      </div>
    </div>
    <div class="title_sa" v-else>
      <div class="ims">
        <div>
          <img src="@/assets/images/header/wicon.png" @click="collect()" />
        </div>|
        <div class="sp" @click="shopping()">
          <el-badge :value="getcar" class="item" v-if="getcar"></el-badge>
          <img src="@/assets/images/header/icon_3.png" />
        </div>|
        <div @click="logins()" class="my">
          <img src="@/assets/images/header/icon_4.png" />
          <span>{{getuserdata?getuserdata.nick:false || "登录"}}</span>
        </div>
      </div>
    </div>

    <div class="content" v-if="this.$store.getters['user/getToken']">
      <h3 class="title">个人中心</h3>
      <p class="content_s">便于您快速了解订单详情及伊莉丝钻石定制进程，及时获取生日礼包等尊享服务信息</p>
      <div class="log_out" @click="exit()">退出登录></div>
    </div>

    <div class="content" v-if="!this.$store.getters['user/getToken']">
      <h3 class="title">登录或创建账户</h3>
      <p class="content_s"></p>
      <div class="log_outs" @click="zc()">
        注册新账户
        <span>></span>
      </div>
      <div class="log_outs" @click="login()">
        登录
        <span>></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "login",
  props: ["type"],
  computed: {
    ...mapGetters("user", {
      getuserdata: "getuserdata"
    }),
    ...mapGetters("user", {
      getcar: "getcar",
      getuserdata: "getuserdata"
    })
  },
  data() {
    return {};
  },
  methods: {
    loading() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
        target: ".sub-main",
        body: true,
        customClass: "mask"
      });
      setTimeout(function() {
        loading.close();
      }, 300);
    },
    exit() {
      this.$store.dispatch("user/logout");
      let loading = this.$loading({
        lock: true,
        text: "退出成功...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      setTimeout(() => {
        loading.close();
        this.$router.push({ path: "/createLogin.html" });
      }, 300);
    },
    zc() {
      this.loading();
      this.$router.push({ path: "/register" });
    },
    login() {
      if (this.$store.getters["user/getToken"]) {
        this.loading();
        window.location.href = "/account";
        // this.$router.push({path:'/mycenter'})
      } else {
        this.loading();
        window.location.href = "/createLogin.html";
      }
    },
    logins() {
      if (this.$store.getters["user/getToken"]) {
        this.loading();
        // this.$router.push({path:'/mycenter'})
        window.location.href = "/account";
      } else {
        this.loading();
        window.location.href = "/createLogin.html";
      }
    },
    //收藏
    collect() {
      if (this.$store.getters["user/getToken"]) {
        this.loading();
        this.$router.push({ path: "/collect" });
      } else {
        this.loading();
        this.$router.push({ path: "/createLogin.html" });
      }
    },
    //购物车
    shopping() {
      if (this.$store.getters["user/getToken"]) {
        this.loading();
        this.$router.push({ path: "/shopping" });
      } else {
        this.loading();
        this.$router.push({ path: "/createLogin.html" });
      }
    },
    car() {
      if (this.$store.getters["user/getToken"]) {
        this.loading();
        this.$router.push({ path: "/shopping" });
      } else {
        this.loading();
        this.$router.push({ path: "/createLogin.html" });
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.login_s {
  width: 426px; /* no */
  height: 100vh;
  box-shadow: -7px 0px 7px -7px #5e5e5e;
  .title_sa {
    background: #921d22;
    height: 70px; /* no */
    width: 100%;
    color: #fff;
    margin: 0;
    padding: 0;
    img {
      width: 25px; /* no */
      height: 25px; /* no */
    }
    span {
      display: inline-block;
      width: 40px; /* no */
      overflow: hidden;
      white-space: nowrap;
      height: 30px; /* no */
      margin-right: 8px; /* no */
      line-height: 32px; /* no */
      font-size: 14px; /* no */
    }
  }
  .title {
    margin-top: 90px;
    font-size: 30px;
    color: #333333;
    font-weight: 400;
  }
  .content {
    box-sizing: border-box;
    padding: 40px;
    .content_s {
      margin-top: 20px;
      margin-bottom: 120px;
    }
  }
}
.log_outs {
  width: 100%;
  height: 25px;
  font-size: 14px;
  color: #333333;
  margin-top: 36px;
  span {
    display: inline-block;
    padding-left: 10px;
  }
}
.ims {
  width: 200px; /* no */
  height: 96%;
  float: right;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 10px;
  span {
    color: #fff;
    line-height: 26px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 10px;
  }
}
.sp {
  position: relative;
}
.item {
  position: absolute;
  top: -9px; /* no */
  right: -8px;
}
.my {
  width: 80px; /* no */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}
.main-header .item /deep/ .el-badge__content {
  background: #fff;
  width: 10px; /* no */
  height: 20px; /* no */
  line-height: 20px; /* no */
  border-radius: 50px; /* no */
  text-align: center;
  color: #000;
}
.spsw {
  position: relative;
  width: 25px; /* no */
}
.spsw .item /deep/ .el-badge__content {
  background: #fff;
  width: 12px; /* no */
  height: 20px; /* no */
  line-height: 20px; /* no */
  border-radius: 50px; /* no */
  color: #000;
}

@media screen and (max-width: 1350px) {
  .login_s {
    width: 390px; /* no */
  }
}
@media screen and (max-width: 1350px) {
  .login_s {
    width: 360px; /* no */
  }
}
@media screen and (max-width: 1000px) {
  .login_s {
    width: 330px; /* no */
  }
}
</style>
